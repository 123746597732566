.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.loading, .error {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
}

.error {
  color: #ff0000;
}

.row-todo {
  background-color: #ffcccb !important; /* Light red */
}

.row-no-need {
  background-color: #e0e0e0 !important; /* Light gray */
}

.row-ongoing {
  background-color: #ffffcc !important; /* Light yellow */
}

.row-done {
  background-color: #90ee90 !important; /* Light green */
}
